<template>
    <div class="user-management">
      <h1>Busy</h1>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
      };
    },
    methods: {
    },
  };
  </script>
  
  <style scoped>
  .user-management {
    margin-top: 64px;
  }
  </style>
  